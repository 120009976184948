import { Injectable, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { AuthService } from '../../_services/auth.service';
import { Kullanici } from "../../_models/bilnex/entity/kullanici.model";
 
@Injectable({
  providedIn: 'root'
})
export class KullaniciService {
  public currentUser: any;
   constructor(
      private http: HttpClient, 
      public authService: AuthService
    ) {

  }
 
  getList(criterie): Observable<Kullanici[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.authService.doLogin(this.currentUser);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.currentUser.AccessToken}`
    });
    return this.http.get<Kullanici[]>(`${this.authService.apiBaseURL}/kullanici/get`, {headers: headers });
  }

  get(code, access_token, apiBaseURL): Observable<Kullanici> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    });
    return this.http.get<Kullanici>(`${apiBaseURL}/Kullanici/Get/`+ code, {headers: headers });
  }

  post(value) {
    return new Promise<any>((resolve, reject) => {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.authService.doLogin(this.currentUser);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.currentUser.AccessToken}`
      });
      this.http.post<any>(`${this.authService.apiBaseURL}/kullanici/add`, value, { headers: headers }).subscribe({
        next: result => {
          if(result!=null){
            resolve(value);
          }
        },
        error: error => {
          reject(error)
        }
      });
    });
  }

  put(value) {
    return new Promise<Kullanici>((resolve, reject) => {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.authService.doLogin(this.currentUser);
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.currentUser.AccessToken}`
      });
      this.http.put<Kullanici>(`${this.authService.apiBaseURL}/kullanici/update`, value, { headers: headers }).subscribe({
        next: result => {
          if(result!=null){
            resolve(value);
          }
        },
        error: error => {
          reject(error)
        }
      });
    });
  }

  delete(value) {
    return new Promise<any>((resolve, reject) => {

      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.authService.doLogin(this.currentUser);
      
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.currentUser.AccessToken}`
      })

      const options = {
        headers: headers,
        body: value
      }
      
      this.http.delete(`${this.authService.apiBaseURL}/kullanici/delete`, options).subscribe({
        next: result => {
          resolve(value);
        },
        error: error => {
          reject(error)
        }
      });

    });
  }

  sendPassword(code): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.get<any>(`${this.authService.apiBaseURL}/kullanici/sendPassword?KODU=` + code, {headers: headers });
  }


}

