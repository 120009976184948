<!-- <app-navigation></app-navigation> -->
<!-- <router-outlet></router-outlet> -->
<!-- <app-footer></app-footer> -->








<!-- Sidebar main menu -->
<app-sidebar  class="sidebar-wrap  sidebar-overlay"></app-sidebar>
<!-- Add pushcontent or fullmenu instead overlay , keep 'sidebar-[choice here]' -->
<!-- Sidebar main menu ends-->

<!-- Begin page -->
<main #mainPage>

    <!-- Header -->
    <app-headermenu class="header position-fixed" #HeaderEl></app-headermenu>
    <!-- Header ends -->

    <!-- main page content -->
    <div class="main-container container">
        <div class="clearfix"></div>
        <router-outlet></router-outlet>
        <div class="clearfix"></div>
    </div>
    <!-- main page content ends -->

</main>
<!-- Page ends-->

 <!-- Footer -->
<app-staticfooter class="footer" #FooterEl></app-staticfooter>
 <!-- Footer Ends -->

 <!-- Footer center menu  Modal -->
<div class="modal fade menumodal" id="menumodal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content shadow">
            <div class="modal-body">
                <h1 class="mb-4"><span class="text-secondary fw-light">Quick</span><br />Actions!</h1>
                <div class="text-center">
                    <img src="assets/img/QRCode.png" alt="" class="mb-2" />
                    <p class="small text-secondary mb-4">Ask to scan this QR-Code<br />to accept money</p>
                </div>
                <div class="row justify-content-center mb-4">
                    <div class="col-auto text-center">
                        <a routerLink="/bills" class="avatar avatar-70 p-1 shadow-sm shadow-danger rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                            <div class="icons text-danger">
                                <i class="bi bi-receipt-cutoff size-24"></i>
                            </div>
                        </a>
                        <p class="size-10 text-secondary">Pay Bill</p>
                    </div>

                    <div class="col-auto text-center">
                        <a routerLink="/sendmoney" class="avatar avatar-70 p-1 shadow-sm shadow-purple rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                            <div class="icons text-purple">
                                <i class="bi bi-arrow-up-right size-24"></i>
                            </div>
                        </a>
                        <p class="size-10 text-secondary">Send Money</p>
                    </div>

                    <div class="col-auto text-center">
                        <a routerLink="/receivemoney" class="avatar avatar-70 p-1 shadow-sm shadow-success rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                            <div class="icons text-success">
                                <i class="bi bi-arrow-down-left size-24"></i>
                            </div>
                        </a>
                        <p class="size-10 text-secondary">Receive Money</p>
                    </div>
                </div>
                <div class="row justify-content-center mb-2">
                    <div class="col-auto text-center">
                        <a routerLink="/withdraw" class="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                            <div class="icons text-secondary">
                                <i class="bi bi-bank size-24"></i>
                            </div>
                        </a>
                        <p class="size-10 text-secondary">Withdraw</p>
                    </div>

                    <div class="col-auto text-center">
                        <a routerLink="/addmoney" class="avatar avatar-70 p-1 shadow-sm shadow-warning rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                            <div class="icons text-warning">
                                <i class="bi bi-wallet2 size-24"></i>
                            </div>
                        </a>
                        <p class="size-10 text-secondary">Add Money</p>
                    </div>

                    <div class="col-auto text-center">
                        <a routerLink="/recharge" class="avatar avatar-70 p-1 shadow-sm shadow-info rounded-20 bg-opac mb-2" data-bs-dismiss="modal">
                            <div class="icons text-info">
                                <i class="bi bi-tv size-24"></i>
                            </div>
                        </a>
                        <p class="size-10 text-secondary">Recharge</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  Footer center menu  ends-->