import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login';
import { DemoComponent } from './demo';
import { RecoverPasswordComponent } from './recover-password';
import { AppSettingsComponent } from './app-settings';
import { ChangelogComponent } from './changelog/changelog.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
const appRoutes: Routes = [
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'termCondition', component: TermsConditionComponent },
  // Full layout
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'recover-password', component: RecoverPasswordComponent },
      { path: 'app-settings', component: AppSettingsComponent },
      { path: 'demo', component: DemoComponent },
      { path: '', component: LoginComponent },
      
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
      { path: 'profile', loadChildren: () => import('../app/content/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
      // { path: 'task', loadChildren: () => import('./mobilnex/task/task.module').then(m => m.TaskModule), canActivate: [AuthGuard] },
    ],
  },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'order', loadChildren: () => import('../app/content/order/order.module').then(m => m.OrderModule), canActivate: [AuthGuard] },
      { path: 'stok', loadChildren: () => import('../app/content/stok/stok.module').then(m => m.StokModule), canActivate: [AuthGuard] },
      { path: 'cari', loadChildren: () => import('../app/content/cari/cari.module').then(m => m.CariModule), canActivate: [AuthGuard] },
      
      // { path: 'task', loadChildren: () => import('./mobilnex/task/task.module').then(m => m.TaskModule), canActivate: [AuthGuard] },
    ],
  },
  // otherwise redirect to home
  //{ path: '**', redirectTo: 'changelog' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' });
