import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { KullaniciService } from '../_services/bilnex/kullanici.service';

@Component({
  templateUrl: 'recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  recoverPasswordForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  defaultImage = '../assets/images/portrait/small/default.png';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private alertService: AlertService,
    private kullaniciService: KullaniciService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.recoverPasswordForm = this.formBuilder.group({
      Mail: ['', Validators.required],
    });
    
  }

  get f() {
    return this.recoverPasswordForm.controls;
  }


  sendPassword() {
    this.submitted = true;
    if (this.recoverPasswordForm.invalid) {
      return;
    }
    
    this.kullaniciService.sendPassword(this.recoverPasswordForm.controls.Mail.value).subscribe(Response => {
      this.messageBox(true, "Şifreniz Gönderilmiştir", true);
    });
  }
  
  onReturnPage() {
    this.router.navigate(["/login"]);
  }
  messageBox(status, message, redirect) {
    if(status) {
      window.alert(message);
    }
    else {
      window.alert(message);
    }
    if(redirect) {
      this.onReturnPage();
    }
  }

}
