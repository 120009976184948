import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-home',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css']
})
export class ChangelogComponent implements OnInit {

  @BlockUI('changelog') blockUIChangelog: NgBlockUI;

  
  constructor() { }

  ngOnInit() {
    
  }

}
