<!-- <div class="app-content content" *ngIf="isPageLoaded"> -->

    <main class="container-fluid h-100 ">
        <div class="row h-100">
            <div class="col-11 col-sm-11 mx-auto">
                <!-- header -->
                <div class="row">
                    <header class="header">
                        <div class="row">
                            <div class="col">
                                <div class="logo-small">
                                    <img src="../../assets/images/logo/logo.png" alt="Mobilnex">
                                    <h5>Mobilnex<br /><span class="text-secondary fw-light">Online</span></h5>
                                </div>
                            </div>
                            <!-- <div class="col-auto align-self-center">
                                <a href="signup.html">Sing up</a>
                            </div> -->
                        </div>
                    </header>
                </div>
                <!-- header ends -->
            </div>







            <!-- <div class="scanner-shell">

                <header>
                    <select (change)="onDeviceSelectChange($event.target.value)">
                        <option value="" [selected]="!currentDevice">No Device Selected</option>
                        <option *ngFor="let device of devicesEngin" [value]="device.deviceId" [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{ device.label }}</option>
                    </select>
                </header>
            
                <zxing-scanner #scanner start="true" [device]="currentDevice" (scanSuccess)="handleQrCodeResult($event)" [formats]="allowedFormats" style="width: 300px;"></zxing-scanner>
            
                <section class="results" *ngIf="qrResultString">
                    <small>Result</small>
                    <strong>{{ qrResultString }}</strong>
                </section>
                
            </div> -->


                <!-- <button onclick="Print.postMessage('kamera-ac');">Print.postMessage('kamera-ac')</button>
                <button onclick="window.postMessage('kamera-ac');">window.postMessage('kamera-ac')</button>
                <br><br>
                <input id="barcode" name="barcode" title="barcode" type="text" />
                <div style="clear: both; margin-bottom: 20px;"></div>

                <input id="MyPHPStringVar" name="MyPHPStringVar" title="MyPHPStringVar" type="text" />
                <div style="clear: both; margin-bottom: 20px;"></div>
                <input type="button" id="btnTik" value="tıkla aç" onclick="alert(document.getElementById('barcode').value)">
                <div style="clear: both; margin-bottom: 20px;"></div>
                <script type='text/javascript'>
                    Print.postMessage('<?php Print($MyPHPStringVar); ?>');
                </script> -->
                <!-- <script type="text/javascript">
                    var _selector = document.querySelector('input[name=myCheckbox]');
                    _selector.addEventListener('change', function(event) {
                        var message = (_selector.checked) ? "Toggle Switch is on" : "Toggle Switch is off";
                
                        if (messageHandler) {
                            messageHandler.postMessage(message);
                        }
                        });
                </script> -->


<!-- 

            <zxing-scanner  #scanner  [autofocusEnabled]="true" [autostart]="true" [enable]="true"  [device]="currentDevice"
                            [formats]="allowedFormats"
                            [tryHarder]="true"  style="width: 400px; height: 400px;"></zxing-scanner> -->

                            























            <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                <div class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
                    <h1 class="mb-4 mt-4 text-center">Giriş Yapın</h1>
                    
                    <div class="form-group form-floating mb-3 is-valid">
                        <input type="text" formControlName="UserName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.UserName.errors }" id="UserName" placeholder="Kullanıcı Adı *" required>
                        <label class="form-control-label" for="UserName">Kullanıcı Adı</label>
                    </div>

                    <div class="form-group form-floating is-invalid mb-3">
                        <input type="password" placeholder="Şifre" formControlName="Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Password.errors }" />
                        <button type="button" class="text-danger tooltip-btn" data-bs-toggle="tooltip" data-bs-placement="left" title="Enter valid Password" id="passworderror">
                            <i class="bi bi-info-circle"></i>
                        </button>
                    </div>

                    <input type="checkbox" class="chk-remember" formControlName="rememberMe" name="remember-me" id="remember-me" style="position: absolute; opacity: 0;" checked>
                    
                    <p class="mb-3 text-end">
                        <a [routerLink]="['/recover-password']" class="">
                            Şifremi Unuttum
                        </a>
                    </p>

                </div>
                <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
                    <div class="row ">
                        <div class="col-12 d-grid">
                            <button type="submit" class="btn btn-default btn-lg shadow-sm">GİRİŞ</button>
                        </div>
                    </div>
                    <div class="row">
                        <p class="mb-3 mt-3 col-6">
                            <a (click)="demo()" class="">
                                <small>Demo</small>
                            </a>
                        </p>
                        <p class="mb-3 mt-3 col-6 text-end">
                            <a [routerLink]="['/app-settings']" class="">
                                <small>Ayarlar</small>
                            </a>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    </main>
