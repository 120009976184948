<!-- Add pushcontent or fullmenu instead overlay -->
<div class="closemenu text-muted " (click)="menuclose()">Close Menu</div>
<div class="sidebar ">
    <!-- user information -->
    <div class="row my-3">
        <div class="col-12 profile-sidebar">
            <div class="clearfix"></div>
            <div class="circle small one"></div>
            <div class="circle small two"></div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 194.287 141.794" class="menubg">
                <defs>
                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                        gradientUnits="objectBoundingBox">
                        <stop offset="0" stop-color="#09b2fd" />
                        <stop offset="1" stop-color="#6b00e5" />
                    </linearGradient>
                </defs>
                <path id="menubg"
                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                    transform="translate(-503.892 -111.404)" fill="url(#linear-gradient)" />
            </svg>

            <div class="row mt-3">
                <div class="col-auto">
                    <figure class="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                        <img src="{{this.currentUser.Logo}}" alt="" class="rounded-18">
                    </figure>
                </div>
                <div class="col px-0 align-self-center">
                    <h5 class="mb-2">{{this.currentUser.Name}}</h5>
                    <p class="text-muted size-12">{{this.currentUser.UserName}}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- user emnu navigation -->
    <div class="row">
        <div class="col-12">
            <ul class="nav nav-pills">

                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" routerLink="/home" routerLinkActive="active" (click)="menuclose()">
                        <div class="avatar avatar-40"><i class="bi bi-house-door"></i></div>
                        <div class="col">Anasayfa</div>
                        <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                    </a>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <div class="avatar avatar-40"><i class="fa fa-list"></i></div>
                        <div class="col">Tanımlar</div>
                        <div class="arrow"><i class="bi bi-chevron-down plus"></i> <i class="bi bi-chevron-up minus"></i></div>
                    </a>
                    <ul class="dropdown-menu">
                        
                        <li>
                            <a class="dropdown-item nav-link" routerLink="/stok/list" routerLinkActive="active" (click)="menuclose()">
                                <div class="col align-self-center">&nbsp;&nbsp;&nbsp;Stok</div>
                                <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                            </a>
                        </li>
                        
                        <li>
                            <a class="dropdown-item nav-link" routerLink="/cari/list" routerLinkActive="active" (click)="menuclose()">
                                <div class="col align-self-center">&nbsp;&nbsp;&nbsp;Cari</div>
                                <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                            </a>
                        </li>
                        
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <div class="avatar avatar-40"><i class="fa fa-list"></i></div>
                        <div class="col">Stok</div>
                        <div class="arrow"><i class="bi bi-chevron-down plus"></i> <i class="bi bi-chevron-up minus"></i></div>
                    </a>
                    <ul class="dropdown-menu">
                        
                        <li>
                            <a class="dropdown-item nav-link" routerLink="/stok/fiyat-gor" routerLinkActive="active" (click)="menuclose()">
                                <div class="col align-self-center">&nbsp;&nbsp;&nbsp;Fiyat Gör</div>
                                <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                            </a>
                        </li>
                        
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <div class="avatar avatar-40"><i class="bi bi-cart"></i></div>
                        <div class="col">Sipariş</div>
                        <div class="arrow"><i class="bi bi-chevron-down plus"></i> <i class="bi bi-chevron-up minus"></i></div>
                    </a>
                    <ul class="dropdown-menu">
                        
                        <li>
                            <a class="dropdown-item nav-link" routerLink="/order/all" routerLinkActive="active" (click)="menuclose()">
                                <div class="col align-self-center">&nbsp;&nbsp;&nbsp;Satış Siparişleri</div>
                                <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                            </a>
                        </li>
                        
                        <li>
                            <a class="dropdown-item nav-link border-bottom-nav-menu" routerLink="/order/all" routerLinkActive="active" (click)="menuclose()">
                                <div class="col align-self-center">&nbsp;&nbsp;&nbsp;Sipariş Karşılama</div>
                                <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                            </a>
                        </li>
                        
                        <li>
                            <a class="dropdown-item nav-link" routerLink="/order/all" routerLinkActive="active" (click)="menuclose()">
                                <div class="col align-self-center">&nbsp;&nbsp;&nbsp;Satınalma Siparişleri</div>
                                <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                            </a>
                        </li>
                        
                    </ul>
                </li>

                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/chat" routerLinkActive="active" tabindex="-1" (click)="menuclose()">
                        <div class="avatar avatar-40 icon"><i class="bi bi-chat-text"></i></div>
                        <div class="col">Messages</div>
                        <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                    </a>
                </li> -->

                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/notifications" routerLinkActive="active" tabindex="-1" (click)="menuclose()">
                        <div class="avatar avatar-40 icon"><i class="bi bi-bell"></i></div>
                        <div class="col">Notification</div>
                        <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                    </a>
                </li> -->

                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/blog" routerLinkActive="active" tabindex="-1" (click)="menuclose()">
                        <div class="avatar avatar-40 icon"><i class="bi bi-newspaper"></i></div>
                        <div class="col">Blogs</div>
                        <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                    </a>
                </li> -->

                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/style" routerLinkActive="active" tabindex="-1" (click)="menuclose()">
                        <div class="avatar avatar-40 icon"><i class="bi bi-palette"></i></div>
                        <div class="col">Style <i class="bi bi-star-fill text-warning small"></i></div>
                        <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                    </a>
                </li> -->

                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/pages" routerLinkActive="active" tabindex="-1" (click)="menuclose()">
                        <div class="avatar avatar-40 icon"><i class="bi bi-file-earmark-text"></i></div>
                        <div class="col">Pages <span class="badge bg-info fw-light">new</span></div>
                        <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                    </a>
                </li> -->

                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" tabindex="-1" (click)="menuclose(); logout()">
                        <div class="avatar avatar-40 icon"><i class="bi bi-box-arrow-right"></i></div>
                        <div class="col">Çıkış</div>
                        <div class="arrow"><i class="bi bi-chevron-right"></i></div>
                    </a>
                </li>

            </ul>
        </div>
    </div>
</div>