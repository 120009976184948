<!-- fixed-top-->
<nav
  class="top-header header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
  id="top-header" [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-md-none mr-auto" ><a class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event)"><i class="feather ft-menu font-large-1" ></i></a></li>
        <li class="nav-item">
          <a [routerLink]="['/dashboard/sales']" class="navbar-brand">
            <img class="brand-logo" alt="{{_themeSettingsConfig.brand.brand_name}}" src="../../../../assets/images/logo/logo-light-lg-beyaz.png">
            <!-- <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3> -->
          </a>
        </li>
        <li class="nav-item d-md-none"><a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li>
      </ul>
    </div>
    <div class="navbar-container content">
      <div class="collapse navbar-collapse show" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block"><a [routerLink]="" class="nav-link nav-menu-main menu-toggle hidden-xs" (click)="toggleFixMenu($event)" ><i class="feather ft-menu"></i></a></li>
          <li class="nav-item d-none d-md-block"><a [routerLink]="" class="nav-link nav-link-expand" (click)="toggleFullScreen()" *ngIf ="maximize === 'on'"><i class="ficon feather ft-maximize"></i></a></li>
          <li class="nav-item nav-search"><a [routerLink]="" class="nav-link nav-link-search" (click)="clickSearch()" *ngIf ="search === 'on'"><i class="ficon feather ft-search"></i></a>
            <div class="search-input" [ngClass]="{'open': isHeaderSearchOpen}"><input class="input" type="text" placeholder="Ara..."></div>
          </li>
        </ul>
        <ul class="nav navbar-nav float-right" >
          <!-- <li class="dropdown-language nav-item" ngbDropdown *ngIf ="internationalization === 'on'">
            <a [routerLink]="" class="dropdown-toggle nav-link" ngbDropdownToggle id="dropdown-flag">
              <i class="flag-icon flag-icon-gb"></i><span class="selected-language"></span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownLangMenu">
              <a [routerLink]="" class="dropdown-item">
                <i class="flag-icon flag-icon-gb"></i> English
              </a>
              <a [routerLink]="" class="dropdown-item">
                <i class="flag-icon flag-icon-fr"></i> French
              </a>
              <a [routerLink]="" class="dropdown-item">
                <i class="flag-icon flag-icon-cn"></i> Chinese
              </a>
              <a [routerLink]="" class="dropdown-item">
                <i class="flag-icon flag-icon-de"></i> German
              </a>
            </div>
          </li> -->
          <li class="dropdown-notification nav-item dropdown" ngbDropdown *ngIf ="notification === 'on'">
            <a class="nav-link nav-link-label" ngbDropdownToggle>
              <i class="ficon feather ft-bell"></i>
              <span class="badge badge-pill badge-danger badge-up badge-glow">{{this.orders.length}}</span>
            </a>
            <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
              <li class="dropdown-menu-header">
                <h6 class="dropdown-header m-0"><span class="grey darken-2">Siparişler</span></h6><span
                  class="notification-tag badge badge-default badge-danger float-right m-0">{{this.orders.length}} Yeni Sipariş</span>
              </li>
              <li *ngFor="let item of this.ordersShow; index as i" class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y" fxFlex="auto" [perfectScrollbar]="config">
                <a href="/orders/show/{{item.GUID}}">
                  <div class="media">
                    <div class="media-left align-self-center"><i class="feather ft-plus-square icon-bg-circle bg-cyan"></i>
                    </div>
                    <div class="media-body">
                      <h6 class="media-heading">{{item.PY}}</h6>
                      <p class="notification-text font-small-3 text-muted">{{item.PY_SIPARIS_NO}} numaralı yeni siparişiniz bulunmaktadır</p>
                      <small><time class="media-meta text-muted" datetime="{{item.TARIH}}">{{item.TARIH | date:'dd.MM.yyyy'}}</time></small>
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer"><a class="dropdown-item text-muted text-center" href="/orders/new">Tüm Siparişler</a></li>
            </ul>
          </li>
          <li class="dropdown-notification nav-item" ngbDropdown *ngIf ="email === 'on'">
            <a class="nav-link nav-link-label" ngbDropdownToggle><i class="ficon feather ft-mail"></i></a>
            <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
              <li class="dropdown-menu-header">
                <h6 class="dropdown-header m-0"><span class="grey darken-2">Mesajlar</span></h6><span
                  class="notification-tag badge badge-default badge-warning float-right m-0">0 Yeni Mesaj</span>
              </li>
              <li class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y" fxFlex="auto" [perfectScrollbar]="config">
                <!-- <a href="javascript:void(0)">
                  <div class="media">
                    <div class="media-left"><span class="avatar avatar-sm avatar-online rounded-circle"><img
                          src="../../../../assets/images/portrait/small/avatar-s-19.png" alt="avatar"><i></i></span></div>
                    <div class="media-body">
                      <h6 class="media-heading">Mobilnex</h6>
                      <p class="notification-text font-small-3 text-muted">n11 Entegrasyonumuz Tamamlanmıştır</p>
                      <small><time class="media-meta text-muted" datetime="2021-07-08T13:00:00">08.07.2021 13:00</time></small>
                    </div>
                  </div>
                </a> -->
              </li>
              <li class="dropdown-menu-footer"><a class="dropdown-item text-muted text-center"
                  href="javascript:void(0)">Tümü</a></li>
            </ul>
          </li>

          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="currentUser.Name"
                class="mr-1 user-name text-bold-700">{{currentUser.Name}}</span>
              <span *ngIf="!currentUser.Name" class="mr-1 user-name text-bold-700">Demo</span>
              <span class="avatar avatar-online">
                <img *ngIf="currentUser.Logo" src="{{currentUser.Logo}}" alt="avatar">
                <img *ngIf="!currentUser.Logo" src="../../../assets/custom/images/logo.png" alt="avatar">
                <i></i>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <a class="dropdown-item" [routerLink]="['/user/edit/'+currentUser.UserId]"><i class="feather ft-user"></i> Profil Ayarları </a>
              <a class="dropdown-item" [routerLink]="['/settings/log']"><i class="feather ft-message-square"></i> Log </a>
              <!-- <a class="dropdown-item" [routerLink]="['/email']"><i class="feather ft-mail"></i> Mailler </a> -->
              <!-- <a class="dropdown-item" [routerLink]="['/todos']"><i class="feather ft-check-square"></i> Task </a> -->
              <!-- <a class="dropdown-item" [routerLink]="['/chats']"><i class="feather ft-message-square"></i> Destek </a> -->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="" (click)="logout()"><i class="feather ft-power"></i> Çıkış</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
