<div class="row h-100">
    <div class="col-11 col-sm-11 mx-auto">
        <!-- header -->
        <div class="row">
            <header class="header">
                <div class="row">
                    <div class="col">
                        <div class="logo-small">
                            <img src="../../assets/images/logo/logo.png" alt="Mobilnex">
                            <h5>Mobilnex<br /><span class="text-secondary fw-light">Online</span></h5>
                        </div>
                    </div>
                    <div class="col-auto align-self-center">
                        <a routerLink="/login">Giriş Yap</a>
                    </div>
                </div>
            </header>
        </div>
        <!-- header ends -->
    </div>
    <div class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
        <form class="form-horizontal text-center mt-4" [formGroup]="recoverPasswordForm" (ngSubmit)="sendPassword()">
            <h2 class="mb-3">Şifremi Unuttum</h2>
            <p class="text-secondary mb-4">Şifreyi hatırlatmak için kayıtlı e-posta adresinizi girin.</p>
            <div class="form-group form-floating mb-3 is-valid">
                <input type="email" formControlName="Mail" class="form-control" email [ngClass]="{ 'is-invalid': submitted && f.Mail.errors }" placeholder="E-Posta" required>
                <label class="form-control-label" for="Mail">E-Posta</label>
            </div>
        </form>
    </div>
    <div class="col-11 col-sm-11 mt-auto mx-auto py-4">
        <div class="row ">
            <div class="col-12 d-grid">
                <button type="submit" class="btn btn-default btn-lg shadow-sm">ŞİFREMİ GÖNDER</button>
            </div>
        </div>
    </div>
</div>
