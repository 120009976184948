import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../_services/auth.service';
import { AlertService } from '../../../../_services/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AlertifyService } from '../../../../../app/_services/alertify.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public currentUser: any;
  constructor(
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private alertifyService: AlertifyService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }
  
  menuclose() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('menu-open');
  }
  logout() {
    if (localStorage.getItem('currentUser')) {
      this.authService.doLogout().then(res => {
        this.router.navigate(['/login']);
      }, err => {
        console.log(err);
      });
    }
  }

}
