import { NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig
} from '@angular/platform-browser';
import { SwiperModule } from 'swiper/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  NgbModule,
  NgbCarouselConfig,
  NgbModalConfig
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AuthGuard } from './_guards/auth.guard';
import { AlertComponent } from './_helpers/alert.component';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';
import { HelperService } from './_services/helper.service';
import { ChartApiService } from './_services/chart.api';
import { ApplicationApiService } from './_services/application-api.service';
import { NgCircleProgressModule} from 'ng-circle-progress';

// Routing
import { routing } from './app.routing';
// Components
import { AppComponent } from './app.component';
import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';
import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { RecoverPasswordComponent } from './recover-password';
import { AppSettingsComponent } from './app-settings';
import { LoginComponent } from './login';
import { DemoComponent } from './demo';
import { ChangelogComponent } from './changelog/changelog.component';
import { NavbarService } from './_services/navbar.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { BreadcrumbModule } from './_layout/breadcrumb/breadcrumb.module';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
import { AlertifyService } from './_services/alertify.service';
import { CommonModule } from '@angular/common';
import { NouisliderModule } from 'ng2-nouislider';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import { Daterangepicker } from 'ng2-daterangepicker';

import { StaticfooterComponent } from './_layout/private-layout/partials/staticfooter/staticfooter.component';
import { HeadermenuComponent } from './_layout/private-layout/partials/headermenu/headermenu.component';
import { SidebarComponent } from './_layout/private-layout/partials/sidebar/sidebar.component';

import { FooterinfoComponent } from './_layout/public-layout/partials/footerinfo/footerinfo.component';
import { HeaderbackComponent } from './_layout/public-layout/partials/headerback/headerback.component';
import {TuiAlertModule, TuiDialogModule, TuiRootModule} from '@taiga-ui/core';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        SwiperModule,
        ReactiveFormsModule,
        HttpClientModule,
        ChartsModule,
        NouisliderModule,
        FullCalendarModule,
        Daterangepicker,
        BrowserAnimationsModule,
        BreadcrumbModule,
        NgbModule,
        FormsModule,
        NgCircleProgressModule.forRoot(),
        // AngularFireModule.initializeApp(environment.firebase),
        // AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        // AngularFireAuthModule, // imports firebase/auth, only needed for auth features
         routing,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        ToastrModule.forRoot(),
        NgxSpinnerModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        BlockUIModule.forRoot({
          template: BlockTemplateComponent
        }),
        InfiniteScrollModule,
        TuiRootModule,
        TuiAlertModule,
        TuiDialogModule,
        ZXingScannerModule
    ],
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        PrivateLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        // AppNavigationComponent,
        
        StaticfooterComponent,
        HeadermenuComponent,
        SidebarComponent,

        FooterinfoComponent,
        HeaderbackComponent,

        AlertComponent,
        RecoverPasswordComponent,
        AppSettingsComponent,
        LoginComponent,
        DemoComponent,
        ChangelogComponent,
        BlockTemplateComponent,
        FullLayoutComponent,
        PrivacyPolicyComponent,
        TermsConditionComponent,
        ],
    providers: [
        AuthGuard,
        ChartApiService,
        AlertService,
        NavbarService,
        ApplicationApiService,
        AuthService,
        HelperService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        NgbCarouselConfig,
        NgbModalConfig,
        AlertifyService
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppModule {}
