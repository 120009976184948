<div class="row">
    <div class="col-auto">
        <a href="javascript:void(0)" target="_self" class="btn btn-light btn-44" (click)="menuopen()">
            <i class="bi bi-list"></i>
        </a>
    </div>
    <div class="col text-center">
        <div class="logo-small">
            <img src="assets/images/logo/logo.png" alt="" />
            <h5>Mobilnex<br /><span class="text-secondary fw-light">Online</span></h5>
        </div>
    </div>
    <div class="col-auto">
        <a routerLink="/profile/edit" routerLinkActive="active" class="btn btn-light btn-44">
            <i class="bi bi-person-circle"></i>
            <span class="count-indicator"></span>
        </a>
    </div>
</div>