import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KullaniciService } from '../../app/_services/bilnex/kullanici.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class AuthService {
  // apiBaseURL = 'http://192.168.12.117:9001';
  // licenseBaseURL = 'http://192.168.12.117:9001';
  apiBaseURL = localStorage.getItem('apiAddress');
  licenseBaseURL = localStorage.getItem('apiAddress');
  //localStorage.getItem('apiAddress')
  
  constructor(
    private http:HttpClient,
    private kullaniciService:KullaniciService
    ) {}
    public currentUser: any;
  
  // Login
  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      };

      let tas: boolean = false;

      if(localStorage.getItem("currentUser") != null && localStorage.getItem("currentUser").length > 0) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        const date = new Date(currentUser.ExpiredDate);
        const dateNow = new Date();
        if(date < dateNow) {
          tas = true
          resolve(currentUser);
        }
      }
      
      
      if(tas == false) {
        const body = new URLSearchParams();
        body.append('UserName', value.UserName);
        body.append('Password', value.Password);
        body.append('grant_type', 'password');

        this.http.post<any>(`${this.apiBaseURL}/token`, body.toString(), options).subscribe({ next: resultToken => {
          if(resultToken != null) {
            this.kullaniciService.get(value.UserName, resultToken.access_token, this.apiBaseURL).subscribe(Response => {

              let date: Date = new Date();
              date.setDate(date.getDate() + 1);

              value.AccessToken = resultToken.access_token;
              value.ExpiredDate = date;
              value.Name = Response.ADI;
              value.CompanyName = Response.ADI;
              value.FirmaKodu = Response.FIRMA.toString();
              value.SubeKodu = Response.SUBE_KODU.toString();
              value.VeriTabani = Response.VERI_TABANI;
              value.VeriTabaniAdi = Response.VERI_TABANI.substring(0, Response.VERI_TABANI.length-7);
              
              if(localStorage.getItem('firmaKodu') != null) { value.FirmaKodu = localStorage.getItem('firmaKodu'); }
              if(localStorage.getItem('subeKodu') != null) { value.SubeKodu = localStorage.getItem('subeKodu'); }
              if(localStorage.getItem('veriTabani') != null) { value.VeriTabani = localStorage.getItem('veriTabani'); }
              if(localStorage.getItem('veriTabaniAdi') != null) { value.VeriTabaniAdi = localStorage.getItem('veriTabaniAdi'); }

              this.setUserInStorage(value);

              setTimeout(function(){
                resolve(value);
              },1000);
            });
          }
        },
        error: error => {
          reject(error)
        }
      });
    }
  });
  }

  // Logout
  doLogout() {
    return new Promise<void>((resolve, reject) => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('remember');
      localStorage.removeItem('dref');
      resolve();
    });
  }
  
  // GetUser
  doGetUser() {
    return new Promise<any>((resolve, reject) => {
      var q = localStorage.getItem('currentUser');
      if(q != null){
        resolve(q);
      }
      else {
        reject(null);
      }
    });
  }

  setUserInStorage(res) {
    if (res.access_token) {
      localStorage.setItem('currentUser', JSON.stringify(res));
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res));
    }
  }

  

}
