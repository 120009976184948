import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { KullaniciService } from '../_services/bilnex/kullanici.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  templateUrl: 'demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {
  DemoForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  defaultImage = '../assets/images/portrait/small/default.png';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private alertService: AlertService,
    private kullaniciService: KullaniciService,
    private spinnerService: NgxSpinnerService,
    private alertifyService: AlertifyService,
    private authService: AuthService) {
  }

  demoApiAddress: string = "http://178.157.14.249:9001";
  ngOnInit() {
    this.tryLogin();  
  }

  tryLogin() {
    const value = {
      UserName: 'demo',
      Password: 'demo',
      AccessToken: '',
      ExpiredDate: new Date(),
      Name: '',
      CompanyName: '',
      Logo: './assets/images/logo/logo.png',
      FirmaKodu: 1,
      SubeKodu:1
    };

    this.adoLogin(value);   
  }

  adoLogin(value) {
    this.authService.doLogin(value).then(
      res => {
          localStorage.setItem('remember', 'true');
        this.setUserInStorage(res);
        localStorage.removeItem('currentLayoutStyle');
        let returnUrl = '/dashboard/sales';
        this.router.navigate([returnUrl]);
      },
      err => {
        this.alertifyService.error("Lütfen bilgilerinizi kontrol edin! > "+(err));
        this.router.navigate(['/login']);
      }
    );
  }

  setUserInStorage(res) {
    if (res.AccessToken) {
      localStorage.setItem('currentUser', JSON.stringify(res));
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res));
    }
  }

}
