import { Component, OnInit, VERSION, ViewChild, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AlertifyService } from '../../app/_services/alertify.service';

import {ZXingScannerComponent, ZXingScannerModule} from '@zxing/ngx-scanner';
import { BarcodeFormat, Result } from '@zxing/library';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  isPageLoaded = false;
  //demoApiAddress: string = "http://178.157.14.249:9001";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    private renderer: Renderer2,
    private spinnerService: NgxSpinnerService,
    private alertifyService: AlertifyService
  ) {

    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

    let t = localStorage.getItem("dref");
    if(t != null && t.length > 0 && t == "1") {
      this.router.navigate(['/demo']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      UserName: ['', Validators.required],
      Password: ['', Validators.required],
      rememberMe: false
    });
    // Remember Me
    if (localStorage.getItem('remember')) {
      this.renderer.removeClass(document.body, 'bg-full-screen-image');
      localStorage.removeItem('currentLayoutStyle');
      this.router.navigate(['/dashboard/sales']);
    } else if (localStorage.getItem('currentUser')) {
      // Force logout on login if not remember me
      this.authService.doLogout();
      this.isPageLoaded = true;
    } else {
      this.isPageLoaded = true;
    }

    //this.barcodeInit();
    
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  tryLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.alertifyService.error("Lütfen bilgilerinizi doldurun!");
      return;
    }

    // var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // if(currentUser!=null && currentUser.AccessToken != null && currentUser.AccessToken != "" && currentUser.ExpiredDate > new Date()) {
    //   let returnUrl = '/dashboard/sales';
    //     if (this.returnUrl) {
    //       returnUrl = this.returnUrl;
    //     }
    //     this.router.navigate([returnUrl]);
    // }

    
    const value = {
      UserName: this.f.UserName.value,
      Password: this.f.Password.value,
      AccessToken: '',
      ExpiredDate: new Date(),
      Name: '',
      CompanyName: '',
      Logo: './assets/images/logo/logo.png',
      FirmaKodu: 1,
      SubeKodu:1
    };

    this.adoLogin(value);   
  }

  adoLogin(value) {
    this.authService.doLogin(value).then(
      res => {
        if (this.loginForm.controls['rememberMe'] && this.loginForm.controls['rememberMe'].value) {
          localStorage.setItem('remember', 'true');
        } else {
          localStorage.removeItem('remember');
        }
        this.setUserInStorage(res);
        localStorage.removeItem('currentLayoutStyle');
        let returnUrl = '/dashboard/sales';
        if (this.returnUrl) {
          returnUrl = this.returnUrl;
        }
        this.router.navigate([returnUrl]);
      },
      err => {
        this.submitted = false;
        this.alertifyService.error("Lütfen bilgilerinizi kontrol edin! > "+(err));
      }
    );
  }

  addCheckbox(event) {
    const toggle = document.getElementById('icheckbox');
    if (event.currentTarget.className === 'icheckbox_square-blue') {
      this.renderer.addClass(toggle, 'checked');
    } else if (event.currentTarget.className === 'icheckbox_square-blue checked') {
      this.renderer.removeClass(toggle, 'checked');
    }
  }

  setUserInStorage(res) {
    if (res.AccessToken) {
      localStorage.setItem('currentUser', JSON.stringify(res));
    } else {
      localStorage.setItem('currentUser', JSON.stringify(res));
    }
  }

  demo() {
    localStorage.setItem('apiAddress', 'http://178.157.14.249:9001')
    localStorage.setItem('dref', '1')
    window.location.reload();
    this.router.navigate(['/demo']);
    // this.spinnerService.show();
    // localStorage.setItem('apiAddress', this.demoApiAddress)
    // this.loginForm.controls.UserName.setValue('demo');
    // this.loginForm.controls.Password.setValue('demo');
    // setTimeout(()=>{
    //   this.tryLogin();  
    //   this.spinnerService.hide();
    // }, 1000);
  }





  // allowedFormats = [
  //   BarcodeFormat.AZTEC,
  //   BarcodeFormat.CODABAR,
  //   BarcodeFormat.CODE_39,
  //   BarcodeFormat.CODE_93,
  //   BarcodeFormat.CODE_128,
  //   BarcodeFormat.DATA_MATRIX,
  //   BarcodeFormat.EAN_8,
  //   BarcodeFormat.EAN_13,
  //   BarcodeFormat.ITF,
  //   BarcodeFormat.MAXICODE,
  //   BarcodeFormat.PDF_417,
  //   BarcodeFormat.QR_CODE,
  //   BarcodeFormat.RSS_14,
  //   BarcodeFormat.RSS_EXPANDED,
  //   BarcodeFormat.UPC_A,
  //   BarcodeFormat.UPC_E,
  //   BarcodeFormat.UPC_EAN_EXTENSION
  // ];
  // ngVersion = VERSION.full;
  // @ViewChild('scanner',{static:true}) 
  // scanner: ZXingScannerComponent;
  // hasDevices: boolean = false;
  // hasPermission: boolean;
  // qrResultString: string;
  // qrResult: Result;
  // devicesEngin: MediaDeviceInfo[];
  // currentDevice: MediaDeviceInfo;
  // barcodeInit() {
  //   this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
  //     this.hasDevices = true;
  //     this.devicesEngin = devices;
  //   });
  //   this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
  //   this.scanner.scanComplete.subscribe((result: Result) => this.qrResult = result);
  //   this.scanner.permissionResponse.subscribe((perm: boolean) => this.hasPermission = perm);

  //   setTimeout(()=>{                           // <<<---using ()=> syntax
  //     this.currentDevice = this.devicesEngin[0];
  // }, 2000);
    
  // }
  // handleQrCodeResult(resultString: string) {
  //   console.debug('Result: ', resultString);
  //   console.log(resultString)
  //   this.qrResultString = resultString;
  // }
  // onDeviceSelectChange(selectedValue: string) {
  //   console.debug('Selection changed: ', selectedValue);
  //   this.currentDevice = this.devicesEngin[0];
  // }



}
