// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {  
    items: [
      {
        title: 'Yönetim Paneli',
        icon: 'la-home',
        page: '/dashboard/sales'
      },
      {
        title: 'Ürünler',
        icon: 'la-mobile',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Ürün Listesi',
              icon: 'la-list',
              page: '/product/list'
            },
            {
              title: 'Kategoriler',
              icon: 'la-object-group',
              page: '/category/list'
            },
            {
              title: 'Markalar',
              icon: 'la-registered',
              page: '/brand/list'
            },
            {
              title: 'Renk Tanımları',
              icon: 'la-tag',
              page: '/color/list'
            },
            {
              title: 'Beden Tanımları',
              icon: 'la-tag',
              page: '/size/list'
            },
            {
              title: 'Toplu İşlemler',
              icon: 'la-tag',
              page: '/transactions/price-transactions'
            },
            {
              title: 'Ürün Eşleştir',
              icon: 'la-tag',
              page: '/transactions/product-match'
            }
          ]
        }
      },
      {
        title: 'Siparişler',
        icon: 'la-cart-plus',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Tüm Siparişler',
              icon: 'la-download',
              page: '/orders/all'
            },
            {
              title: 'Yeni Siparişler',
              icon: 'la-download',
              page: '/orders/new'
            },
            {
              title: 'Onaylanmış Siparişler',
              icon: 'la-check-circle',
              page: '/orders/approved'
            },
            {
              title: 'Tamamlanmış Siparişler',
              icon: 'la-check-circle',
              page: '/orders/complete'
            },
            {
              title: 'İptal Siparişler',
              icon: 'la-remove',
              page: '/orders/cancel'
            }
          ]
        }
      },
      {
        title: 'Faturalar',
        icon: 'la-file-pdf-o',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Gönderilmeyi Bekleyen',
              icon: 'la-download',
              page: '/invoice/process'
            },
            {
              title: 'Gönderilen Faturalar',
              icon: 'la-check-circle',
              page: '/invoice/invoiced'
            }
          ]
        }
      },
      {
        title: 'Görevler',
        icon: 'la-list',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Bekleyen',
              icon: 'la-download',
              page: '/task/process'
            },
            {
              title: 'Tamamlanan',
              icon: 'la-check-circle',
              page: '/task/completed'
            }
            ,
            {
              title: 'Hatalı',
              icon: 'la-check-circle',
              page: '/task/error'
            }
          ]
        }
      },
      // {
      //   title: 'Raporlar',
      //   icon: 'la-line-chart',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'En Çok Satan Ürünler',
      //         icon: 'la-area-chart',
      //         page: '/chartjs/charts'
      //       },
      //       {
      //         title: 'Satılan Ürün Listesi',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Sipariş ve Ciro Raporları',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Kategori Bazlı Satış Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Marka Bazlı Satış Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'KDV Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Komisyon Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       }
      //     ]
      //   }
      // },
      {
        title: 'Ayarlar',
        icon: 'la-support',
        page: 'null',
        submenu: {
          items: [
            // {
            //   title: 'Genel Ayarlar',
            //   icon: 'la-puzzle-piece',
            //   page: 'https://modern-admin-8453e.firebaseapp.com/changelog',
            //   isExternalLink: true
            // },
            {
              title: 'Mağaza Ayarları',
              icon: 'la-file',
              page: '/storeSettings/dashboard',
              badge: { type: 'badge-danger', value: '3.0' },
            },
            {
              title: 'Şube Ayarları',
              icon: 'la-file',
              page: '/store-branch/list',
              isExternalLink: true
            },
            {
              title: 'Döviz Kurları',
              icon: 'la-usd',
              page: '/exchange/list',
              isExternalLink: true
            },
            {
              title: 'Genel Ayarlar',
              icon: 'la-settings',
              page: '/settings/edit',
              isExternalLink: true
            },
            // {
            //   title: 'Kargo Ayarları',
            //   icon: 'la-support',
            //   page: 'https://pixinvent.ticksy.com/',
            //   isExternalLink: true
            // },
            // {
            //   title: 'Tasarımlar',
            //   icon: 'la-text-height',
            //   page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
            //   isExternalLink: true,
            // },
            // {
            //   title: 'Yatay Menü',
            //   icon: 'la-caret-square-o-down',
            //   page: 'null'
            // },
            // {
            //   title: 'Dikey Menü',
            //   icon: 'la-caret-square-o-up',
            //   page: 'null'
            // }
          ]
        }
      }
    ]
  },
  vertical_menu: {
    items: [
      {
        title: 'Yönetim Paneli',
        icon: 'la-home',
        page: '/dashboard/sales'
      },
      {
        title: 'Ürünler',
        icon: 'la-mobile',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Ürün Listesi',
              icon: 'la-list',
              page: '/product/list'
            },
            {
              title: 'Kategoriler',
              icon: 'la-object-group',
              page: '/category/list'
            },
            {
              title: 'Markalar',
              icon: 'la-registered',
              page: '/brand/list'
            },
            {
              title: 'Renk Tanımları',
              icon: 'la-tag',
              page: '/color/list'
            },
            {
              title: 'Beden Tanımları',
              icon: 'la-tag',
              page: '/size/list'
            },
            {
              title: 'Toplu İşlemler',
              icon: 'la-tag',
              page: '/transactions/price-transactions'
            },
            {
              title: 'Ürün Eşleştir',
              icon: 'la-tag',
              page: '/transactions/product-match'
            }
          ]
        }
      },
      {
        title: 'Siparişler',
        icon: 'la-cart-plus',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Tüm Siparişler',
              icon: 'la-download',
              page: '/orders/all'
            },
            {
              title: 'Yeni Siparişler',
              icon: 'la-download',
              page: '/orders/new'
            },
            {
              title: 'Onaylanmış Siparişler',
              icon: 'la-check-circle',
              page: '/orders/approved'
            },
            {
              title: 'Tamamlanmış Siparişler',
              icon: 'la-check-circle',
              page: '/orders/complete'
            },
            {
              title: 'İptal Siparişler',
              icon: 'la-remove',
              page: '/orders/cancel'
            }
          ]
        }
      },
      {
        title: 'Faturalar',
        icon: 'la-file-pdf-o',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Gönderilmeyi Bekleyen',
              icon: 'la-download',
              page: '/invoice/process'
            },
            {
              title: 'Gönderilen Faturalar',
              icon: 'la-check-circle',
              page: '/invoice/invoiced'
            }
          ]
        }
      },
      {
        title: 'Görevler',
        icon: 'la-list',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Bekleyen',
              icon: 'la-download',
              page: '/task/process'
            },
            {
              title: 'Tamamlanan',
              icon: 'la-check-circle',
              page: '/task/completed'
            }
            ,
            {
              title: 'Hatalı',
              icon: 'la-check-circle',
              page: '/task/error'
            }
          ]
        }
      },
      // {
      //   title: 'Raporlar',
      //   icon: 'la-line-chart',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'En Çok Satan Ürünler',
      //         icon: 'la-area-chart',
      //         page: '/chartjs/charts'
      //       },
      //       {
      //         title: 'Satılan Ürün Listesi',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Sipariş ve Ciro Raporları',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Kategori Bazlı Satış Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Marka Bazlı Satış Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'KDV Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       },
      //       {
      //         title: 'Komisyon Raporu',
      //         icon: 'la-pie-chart',
      //         page: '/ngchartist/linecharts'
      //       }
      //     ]
      //   }
      // },
      {
        title: 'Ayarlar',
        icon: 'la-support',
        page: 'null',
        submenu: {
          items: [
            // {
            //   title: 'Genel Ayarlar',
            //   icon: 'la-puzzle-piece',
            //   page: 'https://modern-admin-8453e.firebaseapp.com/changelog',
            //   isExternalLink: true
            // },
            {
              title: 'Mağaza Ayarları',
              icon: 'la-file',
              page: '/storeSettings/dashboard',
              badge: { type: 'badge-danger', value: '3.0' },
            },
            {
              title: 'Şube Ayarları',
              icon: 'la-file',
              page: '/store-branch/list',
              isExternalLink: true
            },
            {
              title: 'Döviz Kurları',
              icon: 'la-usd',
              page: '/exchange/list',
              isExternalLink: true
            },
            {
              title: 'Genel Ayarlar',
              icon: 'la-settings',
              page: '/settings/edit',
              isExternalLink: true
            },
            // {
            //   title: 'Kargo Ayarları',
            //   icon: 'la-support',
            //   page: 'https://pixinvent.ticksy.com/',
            //   isExternalLink: true
            // },
            // {
            //   title: 'Tasarımlar',
            //   icon: 'la-text-height',
            //   page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
            //   isExternalLink: true,
            // },
            // {
            //   title: 'Yatay Menü',
            //   icon: 'la-caret-square-o-down',
            //   page: 'null'
            // },
            // {
            //   title: 'Dikey Menü',
            //   icon: 'la-caret-square-o-up',
            //   page: 'null'
            // }
          ]
        }
      }
    ]
  }

};





