import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable()
export class HelperService {
  
  constructor(
    private http:HttpClient
    ) {}
    
  setStorage(name, res) {
    localStorage.setItem(name, res);
  }

  getStorage(name) {
    return localStorage.getItem(name);
  }

  removeStorage(name) {
    return localStorage.removeItem(name);
  }

  cariCinsi() {
    let list:any = [];
    list = [
      {Name:'Müşteri', Value:'Müşteri'},
      {Name:'Satıcı', Value:'Satıcı'},
      {Name:'Müşteri+Satıcı', Value:'Müşteri+Satıcı'},
      {Name:'Bayii', Value:'Bayii'},
      {Name:'Alt Bayii', Value:'Alt Bayii'},
      {Name:'Hedef Müşteri', Value:'Hedef Müşteri'},
      {Name:'Hedef Bayii', Value:'Hedef Bayii'},
      {Name:'Müstahsil', Value:'Müstahsil'},
      {Name:'Diğer', Value:'Diğer'}
    ];
    return list;
  }

  eBelgeTuru() {
    let list:any = [];
    list = [
      {Name:'Ticari Fatura', Value:'0'},
      {Name:'Temel Fatura', Value:'1'},
      {Name:'E-Arşiv Fatura', Value:'2'},
      {Name:'İhracat', Value:'3'},
      {Name:'Kamu', Value:'4'}
    ];
    return list;
  }

  cariAdresTuru() {
    let list:any = [];
    list = [
      {Name:'Genel Adres', Value:'Genel Adres'},
      {Name:'Fatura Adresi', Value:'Fatura Adresi'},
      {Name:'İrsaliye Adresi', Value:'İrsaliye Adresi'},
      {Name:'Sipariş Adresi', Value:'Sipariş Adresi'}
    ];
    return list;
  }

}
