<div class="container">
    <ul class="nav nav-pills nav-justified">
        <li class="nav-item">
            <a class="nav-link" routerLink="/dashboard/sales" routerLinkActive="active">
                <span>
                    <i class="nav-icon fa-solid fa-home"></i>
                    <span class="nav-text">Anasayfa</span>
                </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/stok/fiyat-gor" routerLinkActive="active">
                <span>
                    <i class="nav-icon fa-solid fa-barcode"></i>
                    <span class="nav-text">Fiyat Gör</span>
                </span>
            </a>
        </li>
        <li class="nav-item centerbutton">
            <button type="button" class="nav-link centerbtn" [class.active]="isVisited == true" (click)="checkVisited()">
                <!-- data-bs-toggle="modal" data-bs-target="#menumodal" id="centermenubtn" -->
                <span class="theme-radial-gradient">
                    <i class="bi bi-grid size-22"></i>
                </span>
            </button>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/order/all" routerLinkActive="active">
                <span>
                    <i class="nav-icon fa-solid fa-check-to-slot"></i>
                    <span class="nav-text">Sipari K.</span>
                </span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/profile/edit" routerLinkActive="active">
                <span>
                    <i class="nav-icon fa-solid fa-user"></i>
                    <span class="nav-text">Hesabım</span>
                </span>
            </a>
        </li>
    </ul>
</div>