import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertService } from '../_services/alert.service';
import { KullaniciService } from '../_services/bilnex/kullanici.service';

@Component({
  templateUrl: 'app-settings.component.html',
  styleUrls: ['./app-settings.component.css']
})
export class AppSettingsComponent implements OnInit {
  appSettingsForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  defaultImage = '../assets/images/portrait/small/default.png';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private alertService: AlertService,
    private kullaniciService: KullaniciService,
    private authService: AuthService) {
  }

  ngOnInit() {
    let a:string = "";
    a = localStorage.getItem('apiAddress');
    this.appSettingsForm = this.formBuilder.group({
      apiAddress: [a],
    });
    
  }

  get f() {
    return this.appSettingsForm.controls;
  }


  save() {
    localStorage.setItem('apiAddress', this.appSettingsForm.controls.apiAddress.value);
    this.messageBox(true, "İşleminiz Tamamlanmıştır", true);
  }
  
  onReturnPage() {
    this.router.navigate(["/login"]);
  }
  messageBox(status, message, redirect) {
    if(status) {
      window.alert(message);
    }
    else {
      window.alert(message);
    }
    if(redirect) {
      this.onReturnPage();
    }
  }

}
